#login {
  height: 100%;
  background-color: #f2f2f2;
  overflow: hidden;

  @media screen and (max-width: 767px) {
    background-color: transparent;
    height: auto;
  }
  .container {
    position: relative;
    z-index: 3;
    height: 100%;
    .row {
      height: 100% !important;
      .col-md-* {
        height: 100%;
      }
    }
  }

  .warning {
    color: red;
    text-align: center;
    font-size: 12px;
    display: block;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .card {
    max-width: 390px;
    margin: 0px auto;
    border-radius: 0;
  }

  .card-body {
    padding-top: 45px;
    padding-bottom: 30px;
    padding-left: 34px;
    padding-right: 34px;

    @media screen and (max-width: 767px) {
      padding: 20px;
    }

    .form-row {
      margin-left: 0px;
      margin-right: 0px;

      .form-check {
        text-indent: 10px;
      }

      .lost {
        font-size: 14px;
        line-height: 25px;
      }
    }

    .card-bottom {
      font-size: 12px;

      a {
        text-decoration: none;

        &.more-info-button {
          display: inline-block;
          margin-top: 10px;
          line-height: 130%;
          color: #99d7d8;
          transition: all 400ms ease 0s;
          border-bottom: 1px solid transparent;

          &:hover {
            border-bottom: 1px solid #99d7d8;
          }
        }
      }
    }

    i {
      font-style: normal;
      line-height: 130%;
      display: block;
    }

    .form-group {
      input[type="text"],
      input[type="password"] {
        background-color: transparent;
        box-shadow: none;

        &:-webkit-autofill {
          -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
        }
      }
    }

    .btn {
      &:focus,
      active:focus {
        outline: none;
        box-shadow: none;
        color: white;
      }
    }
  }
}

.login-page {
  width: 100%;
  height: 100%;
  @media screen and (max-width: 767px) {
    /*background-image: url("/mobile_home_bg.png");*/
    background-image: url("/black-bg.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 1000px auto;
    min-height: 100%;
    height: auto;
  }

  #login-bg {
    width: 80%;
    height: 100%;
    position: absolute;
    z-index: 2;
  }

  .map {
    width: 85%;
    height: 100%;
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 1;

    @media screen and (max-width: 767px) {
      display: none;
    }

    .leaflet-container {
      height: 100%;
      width: 100%;
    }
  }
}

.map-border,
.map-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 2;
  -webkit-filter: drop-shadow(11px 2px 16px rgba(50, 50, 0, 0.5));
  filter: drop-shadow(11px 2px 16px rgba(50, 50, 0, 0.5));

  @media screen and (max-width: 767px) {
    display: none;
  }

  .content {
    width: 100%;
    height: 100%;
    border-right: 5px solid #444444;
    //clip-path: polygon(0 0, 76vw 0, 5vw 100vw, 0 100vw);
    clip-path: polygon(0 0, 92% 0, 10% 100%, 0% 100%);
    background-color: #fafafa;
    //background-image: url("/HUN_bekescsaba_cimer.png");
    background-image: url("/cimer.png");
    //background-repeat: no-repeat;
    background-size: 70%;
    background-position: 0%;
  }
}

.map-border {
  left: 12px;
  position: fixed;
  .content {
    background: #444444;
  }
}

.logo {
  margin-top: 0;
  display: inline-block;
  margin-bottom: 60px;

  @media screen and (max-width: 767px) {
    margin-bottom: 25px;
    margin-top: 40px;
    svg {
      width: 170px !important;
      height: auto !important;
    }
  }
}

.place {
  text-transform: uppercase;
  font-size: 21px;
  font-weight: bold;

  @media screen and (max-width: 767px) {
    font-size: 17px;
  }
}

.ums-logo,
.utu-logo {
  height: 50px;
}

.bottom-logos {
  bottom: 30px;
  left: 40px;
  z-index: 1000;
  position: absolute;

  @media screen and (max-width: 767px) {
    position: static;
    padding-bottom: 40px;
    padding-top: 40px;
    text-align: left;
    padding-left: 0;
    text-align: center;

    svg {
      height: 40px;
      width: auto;
      & + svg {
        margin-left: 15px;
      }
    }
  }
}

// Modal update
body {
  &.modal-open {
    .modal {
      display: flex !important;
      height: 100%;

      .modal-dialog {
        margin: auto;
      }
    }
  }

  .modal-backdrop {
    background-color: white;
    z-index: 6040;

    &.show {
      opacity: 0.3;
    }
  }

  .modal {
    top: 50%;
    transform: translateY(-50%);
    z-index: 6050;
    .modal-content {
      box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.25);
      border: 0;
    }

    .modal-header {
      font-size: 16px;
      font-weight: 600;
    }

    .modal-footer {
		padding: 12px 31px;
      button {
        color: white;
        min-width: 200px;
        font-weight: 600 !important;
        margin: 0;

        &:focus,
        &:active {
          color: white;
          box-shadow: none !important;
          outline: 0;
          font-weight: 600 !important;
        }
      }
    }

    // Other info modal
    &.other-info-modal {
      .modal-header {
        border: 0;
        flex-wrap: wrap;

        .logo-container {
          margin-top: 20px;
          width: 100%;
          height: 70px;
          text-align: center;
          margin-bottom: 30px;
          @media screen and (max-width: 991px) {
            height: 50px;
          }
          svg {
            height: 70px;
            width: auto;
            @media screen and (max-width: 991px) {
              height: 50px;
            }
          }
        }

        .modal-title {
          text-align: center;
          display: block;
          width: 100%;
          font-size: 17px;
          font-weight: 600;
          @media screen and (max-width: 991px) {
            font-size: 16px;
          }

          @media screen and (max-width: 991px) {
            font-size: 15px;
          }
        }
        .close {
          display: none;
        }
      }

      .modal-body {
        padding-left: 70px;
        padding-right: 70px;
        padding-top: 10px;

        @media screen and (max-width: 991px) {
          padding-left: 15px;
          padding-right: 15px;
          padding-top: 0;
        }

        p {
          text-align: center;
          line-height: 200%;
          font-size: 16px;
          font-weight: 500;
          @media screen and (max-width: 991px) {
            font-size: 15px;
            line-height: 160%;
            margin-bottom: 10px;
          }
          @media screen and (max-width: 991px) {
            font-size: 14px;
          }
          strong {
            font-weight: 700;
          }

          span {
            color: #ff006f;
          }
        }
      }

      .modal-footer {
        border: 0;
        padding-top: 0;
        padding-bottom: 30px;
        text-align: center;
        justify-content: center;

        button {
          color: white;
          min-width: 200px;

          &:focus,
          &:active {
            color: white;
            box-shadow: none !important;
            outline: 0;
          }
        }
      }
    }
  }
}

.show-pass {
  position: absolute;
  right: 52px;
  top: 129px;
  color: #e3e3e3; 
  
  @media screen and (max-width: 767px) {
    top: 104px;
  }
}

//loader
.login-lds-ellipsis {
  display: inline-block;
  position: relative;
  margin: 10px;
  margin-right: 90px;
}
.login-lds-ellipsis div {
  position: absolute;
  top: 0px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.login-lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.login-lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.login-lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.login-lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
